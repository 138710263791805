import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "../CompanyProfile/CompanyProfile.css";
import { ReactComponent as EditIcon } from "../../../assets/icons/editIconColorful.svg";
import companyLogo from "../../../assets/images/intellimorphlogo.png";

const CompanyProfile = () => {
  const navigate = useNavigate();
  const [editCompanyDetails, setEditCompanyDetails] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    website: "intellimorph.ai",
    industry: "software",
    companySize: "company",
    founded: "founded in 2018",
    specialities: "software development",
    location: "england",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails({ ...companyDetails, [name]: value });
  };

  const validateFields = () => {
    for (let key in companyDetails) {
      if (companyDetails[key] === "") {
        toast.error("Please fill in all required fields", {
          duration: 2000,
        });
        return false;
      }
    }
    return true;
  };

  return (
    <div>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="candicate-pipeline-content mt-2 p-5">
        <div className="company-profile-header">
          <div className="company-logo">
            <img src={companyLogo} alt="Company Logo" />
          </div>
          <div className="company-description">
            <p>
              At Intellimorph, we pride ourselves for being digital
              transformation experts with an extensive experience in delivering
              robotic automation projects. Our team has proven expertise in some
              of the largest automation programmes in Europe, delivering
              solutions across different sectors. More recently we’ve been
              moving into the more advanced A.I. technologies to augment the
              capabilities of an organisation’s work force and realise more
              efficiencies and productivity gains. Our approach in building
              partnerships with our clients ensures that we deliver solutions in
              a collaborated rather than prescription manner.
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-end p-0 m-0">
          <EditIcon
            className="company-profile-edit-icon"
            onClick={() => {
              if (validateFields()) {
                setEditCompanyDetails(!editCompanyDetails);
              }
            }}
          />
        </div>
        <div className="company-profile-container">
          <div className="company-info">
            <div className="company-info-title">Website</div>
            <div className="company-info-description">
              {editCompanyDetails ? (
                <input
                  type="text"
                  name="website"
                  value={companyDetails?.website}
                  onChange={handleInputChange}
                  className="company-input form-control"
                />
              ) : (
                <a href={companyDetails?.website} className="website-link">
                  {companyDetails?.website}
                </a>
              )}
            </div>
          </div>
          <div className="company-info">
            <div className="company-info-title">Industry</div>
            <div className="company-info-description">
              {editCompanyDetails ? (
                <input
                  type="text"
                  name="industry"
                  value={companyDetails?.industry}
                  onChange={handleInputChange}
                  className="company-input form-control"
                />
              ) : (
                companyDetails?.industry
              )}
            </div>
          </div>
          <div className="company-info">
            <div className="company-info-title">Company size</div>
            <div className="company-info-description">
              {editCompanyDetails ? (
                <input
                  type="text"
                  name="companySize"
                  value={companyDetails?.companySize}
                  onChange={handleInputChange}
                  className="company-input form-control"
                />
              ) : (
                companyDetails?.companySize
              )}
            </div>
          </div>
          <div className="company-info">
            <div className="company-info-title">Founded</div>
            <div className="company-info-description">
              {editCompanyDetails ? (
                <input
                  type="text"
                  name="founded"
                  value={companyDetails?.founded}
                  onChange={handleInputChange}
                  className="company-input form-control"
                />
              ) : (
                companyDetails?.founded
              )}
            </div>
          </div>
          <div className="company-info">
            <div className="company-info-title">Specialties</div>
            <div className="company-info-description">
              {editCompanyDetails ? (
                <input
                  type="text"
                  name="specialities"
                  value={companyDetails?.specialities}
                  onChange={handleInputChange}
                  className="company-input form-control"
                />
              ) : (
                companyDetails?.specialities
              )}
            </div>
          </div>
          <div className="company-info">
            <div className="company-info-title">Location</div>
            <div className="company-info-description">
              {editCompanyDetails ? (
                <input
                  type="text"
                  name="location"
                  value={companyDetails?.location}
                  onChange={handleInputChange}
                  className="company-input form-control"
                />
              ) : (
                companyDetails?.location
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between pt-4">
          <div>
            <button
              className="back-btn p-1"
              onClick={() => navigate("/configuration/email-templates")}
            >
              Back
            </button>
          </div>
          <div>
            <button className="continue-btn p-1">Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
