import React, { useState } from "react";
import PdfIcon from "../../assets/icons/Docs_icons.svg";
import EmailIcon from "../../assets/icons/email-icon.svg";
import "./CandidateViewSidebar.css";
import PreliminaryQuestionsModal from "../PreliminaryQuestionsModal/PreliminaryQuestionsModal";
import detailsIcon from "../../assets/icons/details.svg";
import detailsActiveIcon from "../../assets/icons/details - active.svg";
import arrowDown from "../../assets/icons/arrow-down.svg";
import arrowUp from "../../assets/icons/arrow-up.svg";

const CandidateViewSidebar = ({ active, setActive, data }) => {
  const [showModal, setShowModal] = useState(false);
  const [showAll, setShowAll] = useState(false);

  return (
    <div className="candidate-view-sidebar p-3">
      <div className="user-image-container d-flex align-items-center">
        <div className="user-image">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6b4EOx7ysCVbhIujYl8R65V3FPa_GChmzUA&s"
            alt="hi"
          />
        </div>
      </div>
      <div className="candidate-details-container">
        <h5 className="m-0">{data.candidate?.fullName}</h5>
        <p className="text-secondary m-0 pb-1">
          {data.job?.name}, {data.candidate?.currentCompany?.location}
        </p>
        <div className="row text-break email-section">
          <div className="py-1">
            <img src={EmailIcon} width={18} alt="" />
            <span className="m-0 fw-normal px-1">{data.candidate?.email}</span>
          </div>
          <div>
            <img src={PdfIcon} width={18} alt="" />
            <span className="m-0 text-light-blue fw-normal px-1">
              Jasmin Resume.pdf
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-baseline skills-container">
          <div
            className={`col d-flex gap-2 mt-2 flex-wrap overflow-hidden skills-box ${
              showAll ? "show" : "hide"
            }`}
          >
            {data.candidate?.skills?.map((skill, index) => (
              <div
                key={skill.guid}
                className={`skill-needed ${
                  index < 3 || showAll ? "" : "hidden"
                }`}
              >
                {skill.name} <div className="close-icon" onClick={() => {}} />
              </div>
            ))}
          </div>
          <div className="col-auto">
            <img
              src={showAll ? arrowUp : arrowDown}
              alt="dropdown"
              onClick={() => setShowAll(!showAll)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="mt-3 preliminary-question-container">
          <button
            className="btn preliminary-question w-100"
            onClick={() => setShowModal(!showModal)}
            disabled={data?.appliedJobApplicationQuestions?.length === 0}
          >
            Preliminary questions
            <span className="p-1 border rounded">
              {data?.appliedJobApplicationQuestions?.length || 0}
            </span>
          </button>
        </div>
        <div className="d-flex flex-column candidate-btns">
          <button
            className={`btn ${active === "ScheduleInterview" ? "active" : ""}`}
            onClick={() => setActive("ScheduleInterview")}
          >
            Schedule Interview
            <img
              src={
                active === "ScheduleInterview" ? detailsActiveIcon : detailsIcon
              }
              alt="details"
            />
          </button>
          <button
            className={`btn ${active === "InterviewKit" ? "active" : ""}`}
            onClick={() => setActive("InterviewKit")}
          >
            Interview Kit
            <img
              src={active === "InterviewKit" ? detailsActiveIcon : detailsIcon}
              alt="details"
            />
          </button>
          <button
            className={`btn ${active === "PerformanceReview" ? "active" : ""}`}
            onClick={() => setActive("PerformanceReview")}
          >
            Performance Review
            <img
              src={
                active === "PerformanceReview" ? detailsActiveIcon : detailsIcon
              }
              alt="details"
            />
          </button>
        </div>
      </div>
      <PreliminaryQuestionsModal
        showModal={showModal}
        closeModal={setShowModal}
        data={data?.appliedJobApplicationQuestions}
      />
    </div>
  );
};

export default CandidateViewSidebar;
