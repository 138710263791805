import React, { useEffect, useState } from "react";
import "./JobPosting.css";
import FilterIcon from "../../assets/icons/filter.svg";
import searchIcon from "../../assets/icons/search.svg";
import toast, { Toaster } from "react-hot-toast";
import FilterModal from "../../components/common/FilterModal/FilterModal";
import EditJobPostModal from "../../components/EditJobPostModal/EditJobPostModal";
import DataTable from "../../components/common/DataTable/DataTable";
import { useGetJobsQuery } from "../MyActions/MyActionsSlice";
import {
  useGetJobsInsightsQuery,
  useDeleteJobMutation,
} from "./JobPostingSlice";
import { useNavigate } from "react-router-dom";
import deleteIcon from "../../assets/icons/deleteIcon.svg";
import viewIcon from "../../assets/icons/edit.svg";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import DeleteConfirmationModal from "../../components/common/DeleteConfirmationModal/DeleteConfirmationModal";
import { format, parseISO } from "date-fns";

export default function JobPosting() {
  const navigate = useNavigate();

  const [activeFilter, setActiveFilter] = useState("open");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [jobId, setJobId] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [jobInsightsData, setJobInsightsData] = useState("");
  const [skills, setSkills] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobStatusGuid, setJobStatusGuid] = useState(
    "3582db28-91bb-488a-b92f-bda467cf92b4"
  );
  const [jobDetails, setJobDetails] = useState([]);
  const [showDeleteJobModal, setShowDeleteJobModal] = useState(false);

  const {
    data: jobsData,
    isLoading: jobsDataIsLoading,
    isFetching: jobsDataIsFetching,
    error: jobsDataError,
    refetch,
  } = useGetJobsQuery({
    page: page,
    pageSize: pageSize,
    statusGuid: jobStatusGuid,
  });

  const {
    data: jobsInsightsData,
    isLoading: jobsInsightsDataIsLoading,
    isFetching: jobsInsightsDataIsFetching,
    error: jobsInsightsDataError,
  } = useGetJobsInsightsQuery();

  const [
    deleteJob,
    { isLoading: deleteJoblIsLoading, isSuccess: deleteJobIsSuccess },
  ] = useDeleteJobMutation();

  useEffect(() => {
    if (jobsData) {
      const data = jobsData.items.map((item) => ({
        ...item,
        categoryName: item?.category?.name,
        workTypeName: item?.workType?.name,
        createdBy: item?.createdBy?.name ?? "N/A",
        deadline: dateFomatter(item?.deadline),
      }));
      setJobDetails(data);
      setTotalRecords(jobsData.total);
    }
    if (jobsDataError) {
      toast.error(jobsDataError?.data?.Message, {
        duration: 4000,
      });
      return;
    }
  }, [jobsData, jobsDataError, activeFilter]);

  useEffect(() => {
    if (jobsInsightsData) {
      setJobInsightsData(jobsInsightsData);
    }
    if (jobsInsightsDataError) {
      toast.error(jobsInsightsDataError?.data?.Message, {
        duration: 4000,
      });
      return;
    }
  }, [jobsInsightsData, jobsInsightsDataError]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (jobsData) {
      const filteredJobs = jobDetails.filter((myJob) =>
        Object.values(myJob).some((value) =>
          String(value).toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setFilteredJobs(filteredJobs);
    }
  }, [searchQuery, jobsData]);

  const dateFomatter = (dateString) => {
    return format(parseISO(dateString), "dd/MM/yyyy");
  };

  const handleOnViewClick = (guid) => {
    localStorage.setItem("jobGuid", guid);
    navigate(`job/description/${guid}`);
  };

  const handleSearchFilterOnClick = () => {
    setShowFilterModal(false);
  };

  const handleDeleteJobModalClose = () => {
    setShowDeleteJobModal(false);
  };

  const deletJobOnClick = async () => {
    try {
      const response = await deleteJob({
        guid: jobId,
      }).unwrap();
      handleDeleteJobModalClose();
      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 4000,
        });
        return;
      }
      toast.success("Job Deleted Successfully", {
        duration: 2000,
      });
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 4000,
      });
    }
  };

  const filterOptions = [
    { name: "Date", type: "date" },
    { name: "Job Title", type: "text" },
    { name: "Job Skill", type: "text" },
    { name: " Skill", type: "text" },
  ];

  const handleEditonSave = (id, title, description, skills) => {
    const tempArray = [...jobDetails];
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].id === id) {
        tempArray[i].title = title;
        tempArray[i].description = description;
        tempArray[i].skills = skills;
      }
    }
    setJobDetails(tempArray);
    toast.success("Job Post Updated Successfully", {
      duration: 1500,
    });
    setShowEditModal(false);
  };

  const jobPostingTableColumns = [
    {
      key: "jobTitle",
      field: "title",
      headerText: "Job Title",
      width: "140",
      alignment: "Left",
    },
    {
      key: "description",
      field: "categoryName",
      headerText: "Category",
      width: "140",
      alignment: "Left",
    },
    {
      key: "date",
      field: "deadline",
      headerText: "Deadline",
      width: "120",
      alignment: "Left",
    },
    {
      key: "description",
      field: "workTypeName",
      headerText: "Worktype",
      width: "100",
      alignment: "Left",
    },
    {
      key: "createdBy",
      field: "createdBy",
      headerText: "Created By",
      width: "100",
      alignment: "Left",
    },
    {
      key: "option",
      field: "",
      headerText: "Options",
      width: "100",
      alignment: "Center",
      template: (props) => (
        <div className="edit-icon d-flex align-items-center justify-content-center gap-3">
          <button onClick={() => handleOnViewClick(props?.guid)}>
            <img src={viewIcon} alt="view" />
          </button>
          <button
            onClick={() => {
              setShowDeleteJobModal(true);
              setJobId(props?.guid);
            }}
          >
            <img src={deleteIcon} alt="edit" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="job-posting">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {jobsDataIsLoading ||
      jobsDataIsFetching ||
      jobsInsightsDataIsLoading ||
      deleteJoblIsLoading ? (
        <LoadingSpinner />
      ) : (
        ""
      )}
      <div className="tab-container">
        <div
          className={`col-3 custom-card filter-link open-tab ${
            activeFilter === "open" ? "active" : ""
          }`}
          onClick={() => {
            setActiveFilter("open");
            setJobStatusGuid("3582db28-91bb-488a-b92f-bda467cf92b4");
          }}
        >
          <span className="open">{jobInsightsData?.liveCount}</span>Open
        </div>
        <div
          className={`col-3 custom-card filter-link filled-tab ${
            activeFilter === "filled" ? "active" : ""
          }`}
          onClick={() => {
            setActiveFilter("filled");
            setJobStatusGuid("b3963bc2-2476-49dc-b764-767abcd122ee");
          }}
        >
          <span className="filled">{jobInsightsData?.filledCount}</span>Filled
        </div>
        <div
          className={`col-3 custom-card filter-link not-posted-tab ${
            activeFilter === "not-posted" ? "active" : ""
          }`}
          onClick={() => {
            setActiveFilter("not-posted");
            setJobStatusGuid("67514223-59ba-41d7-9fa5-449b63760fb5");
          }}
        >
          <span className="not-posted">{jobInsightsData?.notPostedCount}</span>
          Not Posted
        </div>
        <div
          className={`col-3 custom-card filter-link not-completed-tab ${
            activeFilter === "not-completed" ? "active" : ""
          }`}
          onClick={() => {
            setActiveFilter("not-completed");
            setJobStatusGuid("f8ad2e83-4fb1-4d91-869b-11374bcbccf4");
          }}
        >
          <span className="not-completed">
            {jobInsightsData?.notCompletedCount}
          </span>
          Not Completed
        </div>
      </div>
      <div className="custom-card px-0 pb-0" style={{ position: "relative" }}>
        <div className="row gap-2 justify-content-between align-items-center">
          <div className="col-12 col-md-auto border-2 px-4">
            <div className="d-flex">
              <div>
                <button
                  className="btn job-btn"
                  onClick={() => navigate("/my-actions/new-job-posting")}
                >
                  New Job Posting
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-auto border-2 d-flex py-3 px-4">
            <div style={{ position: "relative" }}>
              <div className="search-icon">
                <img src={searchIcon} alt="Search" />
              </div>
              <div className="px-2">
                <input
                  id="file-search-input"
                  type="text"
                  className="form-control custom-input w-100 h-100 pl-3"
                  placeholder="Search Jobs"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div>
              <button onClick={() => {}} className="btn p-0">
                <img src={FilterIcon} alt="filter" />
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="w-100">
            <DataTable
              data={searchQuery ? filteredJobs : jobDetails}
              columns={jobPostingTableColumns}
              defaultPaging={false}
              setPage={setPage}
              pageSize={pageSize}
              totalRecords={totalRecords}
              selectable={true}
              tableName={"candidate-pipeline-details-table"}
            />
          </div>
        </div>
        <select
          className="border-0 page-selector"
          onChange={(e) => setPageSize(e.target.value)}
        >
          <option selected={pageSize === 5} value={5}>
            Display 5 Jobs
          </option>
          <option selected={pageSize === 10} value={10}>
            Display 10 Jobs
          </option>
          <option selected={pageSize === 20} value={20}>
            Display 20 Jobs
          </option>
          <option selected={pageSize === 50} value={50}>
            Display 50 Jobs
          </option>
        </select>
        <FilterModal
          showModal={showFilterModal}
          closeModal={() => setShowFilterModal(!showFilterModal)}
          filters={filters}
          setFilters={setFilters}
          confirmSubmit={handleSearchFilterOnClick}
          filterOptions={filterOptions}
        />
      </div>
      <EditJobPostModal
        showModal={showEditModal}
        closeModal={setShowEditModal}
        jobId={jobId}
        jobTitle={jobTitle}
        jobDescription={jobDescription}
        requiredSkills={skills}
        confirmSubmit={handleEditonSave}
      />
      <DeleteConfirmationModal
        showModal={showDeleteJobModal}
        closeModal={handleDeleteJobModalClose}
        type={"job"}
        confirmDelete={deletJobOnClick}
      />
    </div>
  );
}
