import { React, useEffect, useState } from "react";
import "./FilterModal.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const FilterModal = ({
  closeModal,
  showModal,
  confirmSubmit,
  filters,
  setFilters,
  filterOptions,
}) => {
  const [formData, setFormData] = useState({});
  const jobStatuses = [
    {
      guid: "3582db28-91bb-488a-b92f-bda467cf92b4",
      name: "Open",
    },
    {
      guid: "b3963bc2-2476-49dc-b764-767abcd122ee",
      name: "Filled",
    },
    {
      guid: "67514223-59ba-41d7-9fa5-449b63760fb5",
      name: "Not Posted",
    },
    {
      guid: "f8ad2e83-4fb1-4d91-869b-11374bcbccf4",
      name: "Not Completed",
    },
  ];

  const handleFilerSubmit = (e) => {
    e.preventDefault();
    confirmSubmit(formData);
    closeModal();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const resetFilters = () => {
    const emptyFormData = Object.fromEntries(
      Object.keys(formData).map((key) => [key, ""])
    );
    setFormData(emptyFormData);
    confirmSubmit(emptyFormData);
  };

  return (
    <Modal
      style={{ padding: 0 }}
      isOpen={showModal}
      className="top-right-modal"
    >
      <ModalHeader
        className="filter-modal-header"
        toggle={() => closeModal(!showModal)}
      >
        <span className="filter-modal-title">Filter</span>
      </ModalHeader>
      <form onSubmit={handleFilerSubmit}>
        <ModalBody className="filter-modal text-secondary">
          <div className="row mb-1 justify-content-center">
            <div className="col-auto px-2">
              <div>
                {filterOptions.map(
                  (item, index) =>
                    item.field === "statusGuid" && (
                      <div key={index} className="px-2 py-1">
                        <label htmlFor={item.name} className="py-1">
                          {item.name}
                        </label>

                        <select
                          id={item.name}
                          name={item.field}
                          className="form-select"
                          onChange={handleChange}
                          value={formData[item.field]}
                        >
                          <option value={""}>Choose Job Status</option>
                          {jobStatuses.map((status) => (
                            <option key={status.guid} value={status.guid}>
                              {status.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )
                )}
              </div>
            </div>
            <div className="col-5 pt-2">
              {filterOptions.map(
                (item, index) =>
                  3 < index && (
                    <div className="px-2 py-1">
                      <label htmlFor={item.name} className="py-1">
                        {item.name}
                      </label>
                      <input
                        type={item.type}
                        name={item.name}
                        className="form-control filter-modal-input pl-3"
                        placeholder={item.name}
                        value={item.value}
                        onChange={handleChange}
                      />
                    </div>
                  )
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="tender-question-modal d-flex justify-content-between">
          <Button
            type="reset"
            color="secondary"
            className="btn-cancel-modal"
            onClick={resetFilters}
          >
            Reset Filter
          </Button>
          <Button type="submit" className="btn-confirm-modal">
            Apply Filter
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default FilterModal;
