import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import DataTable from "react-data-table-component";
import editIcon from "../../../../assets/icons/editIconColorful.svg";
import editDisabledIcon from "../../../../assets/icons/edit.svg";
import saveIcon from "../../../../assets/icons/save.svg";
import saveDisabledIcon from "../../../../assets/icons/saveIconDisabled.svg";
import "./InterviewKit.css";
import {
  useGetAppliedJobInterviewQuestionsQuery,
  useUpdateAppliedJobInterviewQuestionsMutation,
} from "../../CandidatePipelineSlice";
import LoadingSpinner from "../../../../components/common/LoadingSpinner/LoadingSpinner";
import toast, { Toaster } from "react-hot-toast";

export default function InterviewKit({ stageGuid }) {
  const [feedback, setFeedback] = useState({ row: "", value: "" });
  const [score, setScore] = useState({ row: "", value: "" });
  const [editRowId, setEditRowId] = useState(null);
  const [questionsData, setQuestionsData] = useState([]);
  const [questionGuid, setQuestionGuid] = useState("");

  const [
    updateAppliedJobInterviewQuestions,
    { isLoading: updateAppliedJobInterviewQuestionsIsLoading },
  ] = useUpdateAppliedJobInterviewQuestionsMutation();

  const {
    data: appliedJobInterviewQuestionsData,
    isLoading: appliedJobInterviewQuestionsDataIsLoading,
    isFetching: appliedJobInterviewQuestionsDataIsFetching,
    isSuccess: appliedJobInterviewQuestionsDataIsSuccess,
    isError: appliedJobInterviewQuestionsDataIsError,
    error: appliedJobInterviewQuestionsDataError,
    refetch: appliedJobInterviewQuestionsDataRefetch,
  } = useGetAppliedJobInterviewQuestionsQuery(stageGuid);

  useEffect(() => {
    if (appliedJobInterviewQuestionsData) {
      const data = appliedJobInterviewQuestionsData.map((question, index) => ({
        id: index + 1,
        ...question,
      }));
      setQuestionsData(data);
    }
  }, [appliedJobInterviewQuestionsData]);

  const handleChangeScore = (row, event) => {
    const newValue = event.target.value.slice(0, 1);
    setScore({ row: row.id, value: newValue });
  };

  const resetFields = () => {
    setEditRowId(null);
    setFeedback({});
    setScore({});
  };

  const updateQuestionOnClick = async () => {
    if (!feedback.value) {
      toast.error("Feedback is required");
      return;
    }

    try {
      const response = await updateAppliedJobInterviewQuestions({
        questionGuid: questionGuid,
        data: {
          feedbacks: [
            {
              guid: feedback.row,
              feedback: feedback.value,
              score: parseInt(score.value),
            },
          ],
        },
      });
      if (response?.error) {
        toast.error(response?.error?.data?.title || "Something Went wrong", {
          duration: 2500,
        });
        return;
      }
      toast.success("Question Updated Successfully", {
        duration: 1800,
      });
      resetFields();
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 2500,
      });
    }
  };

  const handleEditClick = (question) => {
    if (editRowId === question.feedback?.guid) {
      resetFields();
    } else {
      setEditRowId(question.feedback?.guid);
      setQuestionGuid(question.guid);
      setFeedback({
        row: question.feedback?.guid,
        value: question.feedback?.feedback,
      });
      setScore({
        row: question.feedback?.guid,
        value: question.feedback?.score,
      });
    }
  };

  return (
    <div className="py-3">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {(appliedJobInterviewQuestionsDataIsFetching ||
        updateAppliedJobInterviewQuestionsIsLoading) && <LoadingSpinner />}
      <div className="card custom-card p-3 pb-5">
        <Table className="preliminary-question-table" bordered responsive>
          <thead>
            <tr>
              <th></th>
              <th className="questions">Questions</th>
              <th className="feedback"> Feedback</th>
              <th className="score">Score</th>
              <th className="options">Options</th>
            </tr>
          </thead>

          <tbody>
            {questionsData.map((question) => (
              <tr key={question.guid}>
                <td style={{ textAlign: "center" }}>{question?.id}</td>
                <td>{question?.question}</td>
                <td>
                  <textarea
                    type="text"
                    value={
                      editRowId === question.feedback?.guid
                        ? feedback.value
                        : question.feedback?.feedback
                    }
                    onChange={(e) =>
                      setFeedback({
                        row: question.feedback?.guid,
                        value: e.target.value,
                      })
                    }
                    disabled={editRowId !== question.feedback?.guid}
                    style={{
                      color:
                        editRowId === question.feedback?.guid
                          ? "black"
                          : "#BDBDBD",
                    }}
                  />
                </td>
                <td>
                  <div className="display-flex align-items-center">
                    <input
                      className="feedback-input score"
                      type="text"
                      value={
                        editRowId === question.feedback?.guid
                          ? score.value
                          : question.feedback?.score
                      }
                      disabled={editRowId !== question.feedback?.guid}
                      onChange={(e) => handleChangeScore(question, e)}
                      style={{
                        color:
                          editRowId === question.feedback?.guid
                            ? "black"
                            : "#BDBDBD",
                      }}
                    />
                    / 10
                  </div>
                </td>
                <td>
                  <div className="d-flex gap-3 options">
                    <img
                      src={
                        editRowId === question.feedback?.guid
                          ? editDisabledIcon
                          : editIcon
                      }
                      role="button"
                      alt="edit"
                      width={16}
                      height={16}
                      onClick={() => handleEditClick(question)}
                    />
                    <img
                      src={
                        editRowId === question.feedback?.guid
                          ? saveIcon
                          : saveDisabledIcon
                      }
                      alt="save"
                      width={16}
                      height={16}
                      onClick={updateQuestionOnClick}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
