import React, { useCallback, useEffect, useState } from "react";
import "./Description.css";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import closeIcon from "../../../assets/icons/close.svg";

import {
  useAddNewJobPostingMutation,
  useUpdateJobPostingMutation,
} from "../newJobPostingSlice";
import { WorkTypes } from "../../../enums/workTypes";
import {
  useGetSkillsQuery,
  useAddSkillMutation,
} from "../../commonDataSlice/skillsSlice";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useGetJobCategoriesQuery } from "../../commonDataSlice/staticDataSlice";
import { useGetJobByGuidQuery } from "../../MyActions/MyActionsSlice";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";
import { JobStatuses } from "../../../enums/jobStatuses";
import { ReactTags } from "react-tag-autocomplete";

export default function Description() {
  const navigate = useNavigate();
  const [skills, setSkills] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const { jobGuid } = useParams();

  const [jobDetails, setJobDetails] = useState({
    title: "",
    description: "",
    responsibilities: "",
    experienceInYears: 0,
    minSalary: 0,
    maxSalary: 0,
    workTypeGuid: "",
    location: "",
    deadline: "",
    externalVisibility: true,
    categoryGuid: "",
    skills: [],
  });

  const {
    data: jobData,
    isLoading: jobDataIsLoading,
    isFetching: jobDataIsFetching,
    isSuccess: jobDataIsSuccess,
    isError: jobDataIsError,
    error: jobDataError,
  } = useGetJobByGuidQuery(jobGuid, { skip: !jobGuid });

  const {
    data: skillList,
    isLoading: skillListIsLoading,
    isFetching: skillListIsFetching,
    isSuccess: skillListIsSuccess,
    isError: skillListIsError,
    error: skillListError,
  } = useGetSkillsQuery();

  const {
    data: jobCategoriesList,
    isLoading: jobCategoriesListIsLoading,
    isFetching: jobCategoriesListIsFetching,
    isSuccess: jobCategoriesListIsSuccess,
    isError: jobCategoriesListIsError,
    error: jobCategoriesListError,
  } = useGetJobCategoriesQuery();

  const [addNewJobPosting, { isLoading: addNewJobPostingIsLoading }] =
    useAddNewJobPostingMutation();

  const [updateJob, { isLoading: updateJobIsLoading }] =
    useUpdateJobPostingMutation();

  const [addSkill, { isLoading: addSkillIsLoading }] = useAddSkillMutation();

  useEffect(() => {
    if (jobData) {
      setJobDetails({
        title: jobData?.title,
        description: jobData?.description,
        responsibilities: jobData?.responsibilities,
        experienceInYears: jobData?.experienceInYears,
        minSalary: jobData?.minSalary,
        maxSalary: jobData?.maxSalary,
        workTypeGuid: jobData?.workType?.guid,
        location: jobData?.location,
        deadline: jobData?.deadline?.split("T")[0],
        externalVisibility: jobData?.isExternalVisible,
        categoryGuid: jobData?.category?.guid,
        skills: jobData?.skills,
      });

      setSkills(
        jobData?.skills?.map((s) => ({ value: s.guid, label: s.name }))
      );
    }
  }, [jobData]);

  useEffect(() => {
    if (skillList) {
      const data = skillList.ids.map((id) => skillList.entities[id]);
      setSuggestions(data?.map((d) => ({ value: d.guid, label: d.name })));
    }
  }, [skillList]);

  useEffect(() => {
    if (jobCategoriesList) {
      const data = jobCategoriesList.ids.map(
        (id) => jobCategoriesList.entities[id]
      );
      setJobCategories(data);
    }
  }, [jobCategoriesList]);

  const toolbarSettings = {
    items: ["Bold", "Italic", "Underline", "CreateLink", "Image"],
    type: "Expand",
  };

  const handleInputChange = (data) => {
    const { name, value } = data;
    setJobDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleValidations = (data, requiredFieldNames) => {
    const allValid = requiredFieldNames.every((field) => {
      const value = data[field.key];

      if (value === "" || value === null || value === undefined) {
        toast.error(`${field.name} is required`);
        return false;
      }

      if (field.key === "deadline") {
        const currentDate = new Date();
        const deadlineDate = new Date(value);

        if (deadlineDate <= currentDate) {
          toast.error(`${field.name} must be a future date`);
          return false;
        }
      }

      return true;
    });

    return allValid;
  };

  const handleReset = () => {
    setJobDetails({
      title: "",
      description: "",
      responsibilities: "",
      experienceInYears: 0,
      minSalary: 0,
      maxSalary: 0,
      workTypeGuid: "",
      location: "",
      deadline: "",
      externalVisibility: true,
      categoryGuid: "",
      skills: [],
    });
  };

  const handleDelete = useCallback(
    (tagIndex) => {
      setSkills(skills.filter((_, i) => i !== tagIndex));
    },
    [skills]
  );

  const handleAddition = async (skill) => {
    try {
      if (skill.value === skill.label) {
        const responsePromise = addSkill({ name: skill.label }).unwrap();
        toast.promise(responsePromise, {
          success: "New Skill Added",
          loading: "Loading...",
          error: "Something Went Wrong...!",
        });
        const response = await responsePromise;
        setSkills((prevSkills) => {
          return [...prevSkills, { ...skill, value: response.guid }];
        });
      } else {
        setSkills((prevSkills) => {
          return [...prevSkills, skill];
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.Message);
    }
  };

  const handleSave = async (saveOnly = false) => {
    try {
      const data = {
        ...jobDetails,
        skills: skills.map((skill) => skill.value),
      };

      const canSave = handleValidations(data, [
        { key: "title", name: "Job Title" },
        { key: "description", name: "Job Description" },
        { key: "responsibilities", name: "Job Responsibility Description" },
        { key: "deadline", name: "Deadline Date" },
        { key: "categoryGuid", name: "Job Category" },
        { key: "workTypeGuid", name: "Job Work Type" },
      ]);
      if (!canSave) {
        return;
      } else {
        let responsePromise;
        if (jobGuid) {
          responsePromise = updateJob({
            guid: jobGuid,
            data: { ...data },
          }).unwrap();
        } else {
          responsePromise = addNewJobPosting(data).unwrap();
        }
        toast.promise(responsePromise, {
          loading: "Loading",
          success: `Job description ${
            jobGuid ? "updated" : "added"
          } successfully`,
          error: `Couldn't ${jobGuid ? "update" : "save"} job description...!`,
        });

        const response = await responsePromise;
        localStorage.setItem("jobGuid", response?.guid);
        if (!saveOnly) {
          navigate(`/my-actions/new-job-posting/hiring-team/${response?.guid}`);
        } else {
          navigate(`/my-actions/new-job-posting/description/${response?.guid}`);
        }
      }
    } catch (error) {
      toast.error(error?.data?.Message || "Something went wrong");
    }
  };

  return (
    <div className="description">
      {jobDataIsLoading && <LoadingSpinner />}
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="custom-card description-card">
        <div className="container-fluid form-container">
          <div className="row mb-4">
            <div className="col-xl-3 col-md-4">
              <p className="required">Job Title</p>
            </div>
            <div className="col-xl-7 col-md-8">
              <input
                className="form-control"
                type="text"
                placeholder="Enter Job Title"
                value={jobDetails.title}
                name="title"
                onChange={(e) => handleInputChange(e.target)}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-3 col-md-4">
              <p className="required">Job Description</p>
            </div>
            <div className="col-xl-7 col-md-8">
              <RichTextEditorComponent
                // toolbarSettings={toolbarSettings}
                placeholder="Enter Job Description"
                value={jobDetails.description}
                change={(args) =>
                  handleInputChange({
                    name: "description",
                    value: args.value
                      ? args.value.replace(/<p>/g, "").replace(/<\/p>/g, "")
                      : "",
                  })
                }
              >
                <Inject
                  services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-3 col-md-4">
              <p className="required">Job Responsibility Description</p>
            </div>
            <div className="col-xl-7 col-md-8">
              <RichTextEditorComponent
                // toolbarSettings={toolbarSettings}
                placeholder="Enter Job Responsibility Description"
                value={jobDetails.responsibilities}
                change={(args) =>
                  handleInputChange({
                    name: "responsibilities",
                    value: args.value
                      ? args.value.replace(/<p>/g, "").replace(/<\/p>/g, "")
                      : "",
                  })
                }
              >
                <Inject
                  services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-3 col-md-4">
              <p className="required">Job Category</p>
            </div>
            <div className="col-xl-7 col-md-8">
              <select
                className="form-select required"
                aria-label="Default select example"
                value={jobDetails.categoryGuid}
                name="categoryGuid"
                onChange={(e) => handleInputChange(e.target)}
              >
                <option value="">Choose...</option>
                {jobCategories?.map((category) => (
                  <option key={category?.id} value={category.guid}>
                    {category?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-3 col-md-4">
              <p className="required">Job Work Type</p>
            </div>
            <div className="col-xl-7 col-md-8 work-type">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="workType"
                  id="remote"
                  checked={jobDetails?.workTypeGuid === WorkTypes.REMOTE}
                  value={WorkTypes.REMOTE}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleInputChange({
                        name: "workTypeGuid",
                        value: e.target.value,
                      });
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="remote">
                  Remote
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="workType"
                  value={WorkTypes.ONSITE}
                  id="onsite"
                  checked={jobDetails?.workTypeGuid === WorkTypes.ONSITE}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleInputChange({
                        name: "workTypeGuid",
                        value: e.target.value,
                      });
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="onsite">
                  Onsite
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="workType"
                  id="hybrid"
                  checked={jobDetails?.workTypeGuid === WorkTypes.HYBRID}
                  value={WorkTypes.HYBRID}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleInputChange({
                        name: "workTypeGuid",
                        value: e.target.value,
                      });
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="hybrid">
                  Hybrid
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-4 align-items-start">
            <div className="col-xl-3 col-md-4">
              <p>Required Skills</p>
            </div>
            <div className="col-xl-7 col-md-8">
              <ReactTags
                selected={skills}
                suggestions={suggestions}
                onDelete={handleDelete}
                onAdd={handleAddition}
                placeholderText="Enter Skills"
                labelText=""
                allowNew
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-3 col-md-4">
              <p>Work Experience</p>
              <p>{"(Number of Years)"}</p>
            </div>
            <div className="col-xl-7 col-md-8">
              <input
                className="form-control"
                type="number"
                min={0}
                value={jobDetails.experienceInYears}
                name="experienceInYears"
                onChange={(e) => handleInputChange(e.target)}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-3 col-md-4">
              <p>External Visibility</p>
            </div>
            <div className="col-xl-7 col-md-8 external-visibility">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="external"
                  id="listed"
                  checked={jobDetails.externalVisibility ? true : false}
                  onChange={(e) =>
                    handleInputChange({
                      name: "externalVisibility",
                      value: e.target.checked,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="listed">
                  Listed
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="external"
                  id="unlisted"
                  checked={jobDetails.externalVisibility ? false : true}
                  onChange={(e) =>
                    handleInputChange({
                      name: "externalVisibility",
                      value: !e.target.checked,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="unlisted">
                  Unlisted
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-3 col-md-4">
              <p>Location</p>
            </div>
            <div className="col-xl-7 col-md-8">
              <select
                className="form-select"
                aria-label="Default select example"
                value={jobDetails.location}
                name="location"
                onChange={(e) => handleInputChange(e.target)}
              >
                <option value="">Choose...</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Australia">Australia</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <p className="required">Deadline Date</p>
            </div>
            <div className="col-xl-7 col-md-8">
              <input
                className="form-control date-input"
                type="date"
                placeholder="Enter Job Description"
                name="deadline"
                value={jobDetails.deadline}
                onChange={(e) => handleInputChange(e.target)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button type="reset" className="btn back-btn" onClick={handleReset}>
              Reset
            </button>
          </div>
          <div className="col-auto">
            <button
              type="submit"
              className="btn back-btn me-2"
              onClick={() => handleSave(true)}
            >
              Save
            </button>
            <button
              type="submit"
              className="btn continue-btn"
              onClick={() => handleSave(false)}
            >
              Save & Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
