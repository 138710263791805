import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import "./EmailTemplateModal.css";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import "./EmailTemplateModal.css";
import expandIcon from "../../assets/icons/expand.svg";
import searchIcon from "../../assets/icons/search filter.svg";
import arrowIcon from "../../assets/icons/arrow.svg";
import emailIcon from "../../assets/icons/email.svg";
import {
  useGetEmailTemplatePlaceholdersQuery,
  useGetEmailTemplateByIdQuery,
  useAddEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
} from "../../features/SettingsPage/settingsSlice";
import LoadingSpinner from "../common/LoadingSpinner/LoadingSpinner";
import toast from "react-hot-toast";
import { isObjectValuesNotEmpty } from "../../utils/commonMethods";

export default function EmailTemplateModal({
  templateGuid,
  closeModal,
  showModal,
}) {
  const textRef = useRef(null);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [placeholder, setPlaceholder] = useState([]);

  const [templateData, setTemplateData] = useState({
    name: "",
    subject: "",
    template: "",
  });
  const [placeholders, setPlaceholders] = useState([]);
  const [placeholderQuery, setPlaceholderQuery] = useState("");

  // const toolbarSettings = {
  //   items: [
  //     "Bold",
  //     "Italic",
  //     "Underline",
  //     "CreateLink",
  //     "SourceCode",
  //     "UnorderedList",
  //   ],
  //   type: "Expand",
  // };

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "|",
      "ClearFormat",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
  };

  const {
    data: emailTemplateData,
    isLoading: emailTemplateDataIsLoading,
    isFetching: emailTemplateDataIsFetching,
    isSuccess: emailTemplateDataIsSuccess,
    isError: emailTemplateDataIsError,
    error: emailTemplateDataError,
  } = useGetEmailTemplateByIdQuery(templateGuid, {
    skip: !templateGuid,
  });

  const {
    data: placeholderData,
    isLoading: placeholderDataIsLoading,
    isFetching: placeholderDataIsFetching,
    isSuccess: placeholderDataIsSuccess,
    isError: placeholderDataIsError,
    error: placeholderDataError,
  } = useGetEmailTemplatePlaceholdersQuery(placeholderQuery);

  useEffect(() => {
    if (emailTemplateData) {
      const data = emailTemplateData?.ids?.map(
        (id) => emailTemplateData?.entities[id]
      );
      const emailTemplate = data[0];
      const replacedText = reverseReplacePlaceholders(
        emailTemplate?.template,
        placeholders
      );
      const updatedTemplate = { ...emailTemplate, template: replacedText };
      setTemplateData(updatedTemplate);
    }
    if (emailTemplateDataIsError) {
      toast.error(emailTemplateDataError?.data?.Message);
    }
  }, [emailTemplateData, emailTemplateDataIsError]);

  const [addEmailTemplate, { isLoading: addEmailTemplateIsLoading }] =
    useAddEmailTemplateMutation();
  const [updateEmailTemplate, { isLoading: updateEmailTemplateIsLoading }] =
    useUpdateEmailTemplateMutation();

  useEffect(() => {
    if (placeholderData) {
      const data = placeholderData?.ids?.map(
        (id) => placeholderData?.entities[id]
      );
      setPlaceholders(data);
    }
    if (placeholderDataIsError) {
      toast.error(placeholderDataError?.data?.Message);
    }
  }, [placeholderData, placeholderDataIsError]);

  const handleSearch = (e) => {
    const queryText = e.target.value;
    setPlaceholderQuery(queryText.toLowerCase());
  };

  useEffect(() => {
    if (templateGuid === "") {
      setTemplateData({
        name: "",
        subject: "",
        template: "",
      });
    }
  }, [templateGuid]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTemplateData({ ...templateData, [name]: value });
  };

  const replacePlaceholders = (text, placeholders) => {
    return text.replace(/\[([^\]]+)\]/g, (match, p1) => {
      const placeholder = placeholders.find((ph) => ph.name === p1);
      return placeholder ? `[${placeholder.variable}]` : match;
    });
  };

  const reverseReplacePlaceholders = (text, placeholders) => {
    return text.replace(/\[([^\]]+)\]/g, (match, p1) => {
      const placeholder = placeholders.find((ph) => ph.variable === p1);
      return placeholder ? `[${placeholder.name}]` : match;
    });
  };

  const handleSubmit = async () => {
    try {
      if (!isObjectValuesNotEmpty(templateData)) {
        toast.error("All Fields need to be filled...!");
        return;
      }
      let responsePromise;

      const replacedText = replacePlaceholders(
        templateData?.template,
        placeholders
      );

      if (templateData?.guid) {
        responsePromise = updateEmailTemplate({
          guid: templateData?.guid,
          data: {
            name: templateData?.name,
            subject: templateData?.subject,
            template: replacedText,
          },
        });
        toast.promise(responsePromise, {
          loading: "Loading...",
          success: "Email Template updated successfully...",
          error: "An Unexptected Error occured",
        });
      } else {
        responsePromise = addEmailTemplate({
          ...templateData,
          template: replacedText,
        }).unwrap();
        toast.promise(responsePromise, {
          loading: "Loading...",
          success: "Email Template added successfully...",
          error: "An Unexptected Error occured",
        });
      }

      await responsePromise;
      closeModal(!showModal);
    } catch (error) {
      toast.error(error?.data?.Message || "Something went wrong...");
    }
  };

  const handleAddPlaceholderToText = (data) => {
    setPlaceholder(data?.name);
    const rteInstance = textRef.current;

    if (rteInstance) {
      // Get the current selection range
      const range = rteInstance.getRange();

      if (range) {
        rteInstance.executeCommand("insertText", `[${data?.name}]`);
        rteInstance.executeCommand("insertText", " ");
      }
    }
  };

  return (
    <Modal
      id="email-template"
      isOpen={showModal}
      className="edit-job"
      size="xl"
      centered={true}
      fullscreen={isFullScreen}
      //   backdrop={false}
      // style={
      //   isFullScreen
      //     ? null
      //     : { marginTop: "0.6rem", marginRight: "0.5rem", overflowY: "hidden" }
      // }
    >
      <ModalHeader
        className="px-3"
        toggle={() => closeModal(!showModal)}
        style={{ position: "relative" }}
      >
        <span className="header-title">Send an Email</span>

        <img className="ms-3" src={arrowIcon} alt="arrow" />
        <img
          src={expandIcon}
          alt="expand"
          className="expand"
          onClick={() => setIsFullScreen(!isFullScreen)}
        />
        {emailTemplateDataIsFetching && (
          <span className="mx-2"> Loading...</span>
        )}
      </ModalHeader>

      <ModalBody className="modal-container">
        <div className="row">
          <div className="col-9 template-body">
            <div className="mb-3">
              <h6>Template Name</h6>
              <input
                className="form-control"
                type="text"
                placeholder="Application confirmation email"
                value={templateData?.name}
                name="name"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <h6>Email's Subject</h6>
              <input
                className="form-control"
                type="text"
                name="subject"
                placeholder="Email subject"
                value={templateData?.subject}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <RichTextEditorComponent
                className="email-x"
                ref={textRef}
                toolbarSettings={toolbarSettings}
                placeholder="Enter Job Description"
                value={templateData?.template || ""}
                change={(args) =>
                  setTemplateData({ ...templateData, template: args.value })
                }
              >
                <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar]} />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="col-3 email-placeholder-container">
            {placeholderDataIsLoading && <LoadingSpinner />}
            <div className="d-flex align-items-center gap-2 mb-3">
              <img src={emailIcon} alt="email" width={20} height={20} />
              <h6 className="mb-0">Email Placeholders</h6>
            </div>
            <div className="mb-4" style={{ position: "relative" }}>
              <div className="search-icon">
                <img src={searchIcon} alt="Search" />
              </div>
              <div>
                <input
                  id="email-search-input"
                  type="text"
                  className="form-control custom-input w-100"
                  placeholder="Filter placeholder"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <p className="inter-text">
              You can insert these into the email and they will be replaced with
              the actual values when the email is sent.
            </p>
            {placeholders.map((item) => (
              <button
                key={item?.guid}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  textAlign: "left",
                }}
                className="p-0 m-0 w-100 placeholder-btn"
                // disabled={!cursorPosition}
              >
                <div
                  className={`email-placeholders ${
                    placeholder === item.name ? "active" : ""
                  }`}
                  onClick={() => handleAddPlaceholderToText(item)}
                >
                  <p>{item.name}</p>
                  <p>Ex: {item.example}</p>
                </div>
              </button>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={addEmailTemplateIsLoading || emailTemplateDataIsFetching}
          className="btn continue-btn save-btn"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
