import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ScheduleModal from "../../../../components/ScheduleModal/ScheduleModal";
import { useScheduleMeetingforAppliedJobInterviewPipelineStageMutation } from "../../CandidatePipelineSlice";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../../../../components/common/LoadingSpinner/LoadingSpinner";

export default function ScheduleInterview({ data }) {
  const { appliedJobGuid } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [dateAssigned, setDateAssigned] = useState("");
  const [meetUrl, setMeetUrl] = useState("");
  const [status, setStatus] = useState("");
  const [
    scheduleMeeting,
    {
      isLoading: scheduleMeetingIsLoading,
      isSuccess: scheduleMeetingIsSuccess,
    },
  ] = useScheduleMeetingforAppliedJobInterviewPipelineStageMutation();

  useEffect(() => {
    if (data) {
      setDateAssigned(data?.dateAssigned ?? "");
      setStatus(data?.status);
      setMeetUrl(data?.meetUrl ?? "");
    }
  }, [data]);

  const sheduleMeetingOnClick = async (meetingUrl, dateAssigned) => {
    try {
      const response = await scheduleMeeting({
        appliedJobGuid: appliedJobGuid,
        guid: data.guid,
        data: {
          meetingUrl,
          dateAssigned,
        },
      });

      if (response?.error) {
        toast.error(response?.error?.data?.title || "Something Went wrong", {
          duration: 2500,
        });
        return;
      }
      toast.success("Meeting Scheduled Successfully", {
        duration: 1800,
      });
      setShowModal(false);
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 2500,
      });
    }
  };

  const handleScheduleDateChange = async (value) => {
    const currentDate = new Date();
    const scheduleDate = new Date(value);
    setDateAssigned(value);

    if (scheduleDate <= currentDate) {
      toast.error(`${scheduleDate.toISOString()} must be a future date`);
      return;
    }
    await sheduleMeetingOnClick(meetUrl, value);
  };

  return (
    <div>
      {scheduleMeetingIsLoading && <LoadingSpinner />}
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="row mb-2">
        <div className="col">
          <div className="card custom-card p-3">
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-between align-items-center section-one">
                <div className="fw-bold">
                  <div className="p-2">Status</div>
                  <div className="p-2">Date Assigned</div>
                </div>
                <div>
                  <div className="p-2">{status}</div>
                  <div className="py-1">
                    <input
                      type="date"
                      className="border-0 form-control assigned-date-input"
                      value={dateAssigned}
                      onChange={(e) => handleScheduleDateChange(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex p-2">
                <div>
                  <div>
                    <span className="fw-bold px-2">URL - </span>
                    {meetUrl ? <a href="/">{meetUrl}</a> : <span>N/A</span>}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div>
                <button
                  className="primary-button"
                  onClick={() => setShowModal(true)}
                >
                  Schedule
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row mb-2">
        <div className="col">
          <div className="card custom-card p-3">
            <div>
              <h6 className="text-secondary">Upcoming</h6>
              <div className="d-flex align-items-center">
                <div className="rounded px-3 upcoming-date-container">
                  <h5 className="fw-bold py-1">Jul</h5>
                  <h5 className="text-danger fw-bold">28</h5>
                </div>
                <div className="px-3">
                  <span className="fw-bold">Hr Interview : </span>
                  <span className="text-secondary fw-bold">
                    To contact again in three days September 08, 2024
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <ScheduleModal
        showModal={showModal}
        closeModal={setShowModal}
        handleOnSubmit={sheduleMeetingOnClick}
        meetUrl={meetUrl}
        dateAssigned={dateAssigned}
      />
    </div>
  );
}
