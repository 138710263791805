import React, { useEffect, useState } from "react";
import { ReactComponent as ActiveLinkIcon } from "../../assets/icons/ActiveLinkIcon.svg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./SettingsPageLayout.css";
import { appliedJobInterviewPiplineStageStatus } from "../../enums/appliedJobInterviewPiplineStageStatus";

const SettingsPageLayout = ({ jobStages, activeLink, setActiveLink }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getDisabledStages = () => {
    let disableStages = false;
    let firstInactiveStageFound = false;
    return jobStages
      ?.map((stage) => {
        if (
          !firstInactiveStageFound &&
          (stage.status.guid ===
            appliedJobInterviewPiplineStageStatus.INCOMPLETED ||
            stage.status.guid ===
              appliedJobInterviewPiplineStageStatus.REJECTED)
        ) {
          firstInactiveStageFound = true;
          return null;
        }
        if (firstInactiveStageFound) {
          disableStages = true;
        }
        return disableStages ? stage.guid : null;
      })
      .filter(Boolean);
  };

  const disabledStages = getDisabledStages();

  useEffect(() => {
    if (jobStages) {
      setActiveLink(jobStages[0]?.jobInterviewPipelineStage?.name);
    }
  }, [jobStages]);

  return (
    <div>
      <div className="settings-page-layout">
        {jobStages?.map((stage, index) => (
          <div
            key={stage.guid}
            className={`settings-page-nav-item ${
              activeLink === stage?.jobInterviewPipelineStage.name
                ? "active-link"
                : ""
            }`}
            onClick={() => {
              if (
                !disabledStages.includes(stage.guid) ||
                stage.status.guid ===
                  appliedJobInterviewPiplineStageStatus.REJECTED
              ) {
                setActiveLink(stage?.jobInterviewPipelineStage.name);
              }
            }}
            style={{
              pointerEvents:
                disabledStages.includes(stage.guid) &&
                stage.status.guid !==
                  appliedJobInterviewPiplineStageStatus.REJECTED
                  ? "none"
                  : "auto",
              opacity:
                disabledStages.includes(stage.guid) &&
                stage.status.guid !==
                  appliedJobInterviewPiplineStageStatus.REJECTED
                  ? 0.5
                  : 1,
            }}
          >
            {stage?.status?.guid ===
            appliedJobInterviewPiplineStageStatus.COMPLETED ? (
              <ActiveLinkIcon />
            ) : (
              <span
                className={`pipline-status-indicator ${
                  stage?.status?.guid ===
                  appliedJobInterviewPiplineStageStatus.INCOMPLETED
                    ? "stage-status-incompleted"
                    : stage?.status?.guid ===
                      appliedJobInterviewPiplineStageStatus.SCHEDULED
                    ? "stage-status-scheduled"
                    : stage?.status?.guid ===
                      appliedJobInterviewPiplineStageStatus.REJECTED
                    ? "stage-status-rejected"
                    : stage?.status?.guid ===
                      appliedJobInterviewPiplineStageStatus.PENDING
                    ? "stage-status-pending"
                    : ""
                }`}
              >
                {++index}
              </span>
            )}

            {stage?.jobInterviewPipelineStage.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SettingsPageLayout;
