import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FilterIcon from "../../assets/icons/filter.svg";
import searchIcon from "../../assets/icons/search.svg";
import "./MyActions.css";
import DataTable from "../../components/common/DataTable/DataTable";
import editIcon from "../../assets/icons/edit.svg";
import viewIcon from "../../assets/icons/view.svg";
import peopleIcon from "../../assets/icons/people.svg";
import { useGetMyJobsQuery, useGetMyJobsInsightsQuery } from "./MyActionsSlice";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import FilterModal from "../../components/common/FilterModal/FilterModal";
import toast, { Toaster } from "react-hot-toast";
import { format, parseISO } from "date-fns";
import { JobStatuses, JobStatusesWithGuid } from "../../enums/jobStatuses";

export default function MyActions() {
  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState("live-jobs");
  const [myJobs, setMyJobs] = useState([]);
  const [totalRecords, setTotalRecords] = useState("");
  const [page, setPage] = useState(1);
  const [filteredMyJobs, setFilteredMyJobs] = useState([]);
  const [myJobInsightsData, setMyJobInsightsData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [statusGuid, setStatusGuid] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState({ statusGuid: "" });

  const {
    data: myJobsDetails,
    isLoading: myJobsDetailsIsLoading,
    error: myJobsDetailsError,
  } = useGetMyJobsQuery({
    page: page,
    pageSize: pageSize,
    statusGuid: filters.statusGuid,
  });

  const {
    data: myJobsInsightsData,
    isLoading: myJobsInsightsDataIsLoading,
    isFetching: myJobsInsightsDataIsFetching,
    error: myJobsInsightsDataError,
  } = useGetMyJobsInsightsQuery();

  useEffect(() => {
    if (myJobsDetails) {
      const data = myJobsDetails.items.map((item) => ({
        ...item,
        categoryName: item?.category?.name,
        workTypeName: item?.workType?.name,
        jobStatus: item?.jobStatus?.name,
        jobStatusGuid: item?.jobStatus?.guid,
        deadline: dateFomatter(item?.deadline),
      }));
      setMyJobs(data);
      setTotalRecords(myJobsDetails.total);
    }
    if (myJobsDetailsError) {
      toast.error(myJobsDetailsError?.data?.Message, {
        duration: 4000,
      });
      return;
    }
  }, [myJobsDetails, myJobsDetailsError]);

  useEffect(() => {
    if (myJobsInsightsData) {
      setMyJobInsightsData(myJobsInsightsData);
    }
    if (myJobsInsightsDataError) {
      toast.error(myJobsInsightsDataError?.data?.Message, {
        duration: 4000,
      });
      return;
    }
  }, [myJobsInsightsData, myJobsInsightsDataError]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (myJobsDetails) {
      const filteredMyJobs = myJobs.filter((myJob) =>
        Object.values(myJob).some((value) =>
          String(value).toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setFilteredMyJobs(filteredMyJobs);
    }
  }, [searchQuery, myJobsDetails, myJobs]);

  const handleEditOptionClick = (guid) => {
    localStorage.setItem("jobGuid", guid);
    navigate(`new-job-posting/description/${guid}`);
  };

  const dateFomatter = (dateString) => {
    return format(parseISO(dateString), "dd/MM/yyyy");
  };

  const filterOnClick = () => {
    setStatusGuid("");
    setShowFilterModal(true);
  };

  const handleSearchFilterOnClick = (data) => {
    setFilters({
      statusGuid: data?.statusGuid,
    });
  };

  const handleAddNewJobPosting = () => {
    localStorage.removeItem("jobGuid");
    navigate("/my-actions/new-job-posting");
  };

  useEffect(() => {
    toast.remove();
  }, []);

  const filterOptions = [
    {
      name: "Job Status",
      field: "statusGuid",
      type: "text",
      value: statusGuid,
      setValue: setStatusGuid,
    },
  ];

  const myJobsTableColumns = [
    {
      key: "jobTitle",
      field: "title",
      headerText: "Job Title",
      width: "140",
      alignment: "Left",
    },
    {
      key: "category",
      field: "categoryName",
      headerText: "Category",
      width: "140",
      alignment: "Left",
    },
    {
      key: "deadline",
      field: "deadline",
      headerText: "Deadline",
      width: "120",
      alignment: "Left",
    },
    {
      key: "workingType",
      field: "workTypeName",
      headerText: "Working Type",
      width: "110",
      alignment: "Left",
    },
    {
      key: "jobStatus",
      field: "jobStatus",
      headerText: "Status",
      width: "110",
      alignment: "Left",
      template: (props) => (
        <div
          className={`job-status ${
            props?.jobStatusGuid === JobStatusesWithGuid.FILLED
              ? "filled"
              : props?.jobStatusGuid === JobStatusesWithGuid.NOT_COMPLETED
              ? "not-completed"
              : props?.jobStatusGuid === JobStatusesWithGuid.NOT_POSTED
              ? "not-posted"
              : props?.jobStatusGuid === JobStatusesWithGuid.OPEN
              ? "open"
              : ""
          }`}
        >
          {props.jobStatus}
        </div>
      ),
    },
    {
      key: "option",
      field: "",
      headerText: "Options",
      width: "120",
      alignment: "Center",
      template: (props) => (
        <div className="edit-icon d-flex align-items-center justify-content-center gap-3">
          <button onClick={() => handleEditOptionClick(props?.guid)}>
            <img src={editIcon} alt="edit" />
          </button>
          <button
            onClick={() => navigate(`/job-candidates?jobGuid=${props.guid}`)}
          >
            <img src={peopleIcon} alt="people" width={20} height={20} />
          </button>
          <button onClick={() => {}}>
            <img src={viewIcon} alt="view" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="dashboard">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {myJobsDetailsIsLoading && <LoadingSpinner />}
      <div className="tab-container">
        <div
          className={`col-6 custom-card filter-link ${
            activeFilter === "live-jobs" ? "active" : ""
          }`}
          onClick={() => setActiveFilter("live-jobs")}
        >
          <span>{myJobInsightsData?.liveCount}</span>Live Jobs
        </div>
        <div
          className={`col-6 custom-card filter-link ${
            activeFilter === "upcoming-meetings" ? "active" : ""
          }`}
          onClick={() => setActiveFilter("upcoming-meetings")}
        >
          <span>{myJobInsightsData?.upcomingMeetingsCount}</span>Upcoming
          Meetings
        </div>
      </div>
      <div className="custom-card px-0 pb-0" style={{ position: "relative" }}>
        <div className="row gap-2 justify-content-between align-items-center">
          <div className="col-12 col-md-auto border-2 px-4">
            <div className="d-flex">
              <div>
                <button
                  className="btn job-btn"
                  onClick={handleAddNewJobPosting}
                >
                  New Job Posting
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-auto border-2 d-flex py-3 px-4">
            <div style={{ position: "relative" }}>
              <div className="search-icon">
                <img src={searchIcon} alt="Search" />
              </div>
              <div className="px-2">
                <input
                  id="file-search-input"
                  type="text"
                  className="form-control custom-input w-100 h-100 pl-3"
                  placeholder="Search Jobs"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div>
              <button onClick={filterOnClick} className="btn p-0">
                <img src={FilterIcon} alt="filter" />
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="w-100">
            <DataTable
              data={searchQuery ? filteredMyJobs : myJobs}
              columns={myJobsTableColumns}
              defaultPaging={false}
              setPage={setPage}
              pageSize={pageSize}
              totalRecords={totalRecords}
              selectable={true}
            />
          </div>
        </div>
        <select
          className="border-0 page-selector"
          onChange={(e) => setPageSize(e.target.value)}
        >
          <option selected={pageSize === 5} value={5}>
            Display 5 Jobs
          </option>
          <option selected={pageSize === 10} value={10}>
            Display 10 Jobs
          </option>
          <option selected={pageSize === 20} value={20}>
            Display 20 Jobs
          </option>
          <option selected={pageSize === 50} value={50}>
            Display 50 Jobs
          </option>
        </select>
        <FilterModal
          showModal={showFilterModal}
          closeModal={() => setShowFilterModal(!showFilterModal)}
          filters={filters}
          setFilters={setFilters}
          confirmSubmit={handleSearchFilterOnClick}
          filterOptions={filterOptions}
        />
      </div>
    </div>
  );
}
